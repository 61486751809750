<template>
	<b-modal id="modal-config" title="Specify GrowArea and Variant">
		<template #default>
			<b-form>
				<b-form-group
					label-cols-sm="4"
					label-cols-lg="3"
					label="Zone:"
				>
					<v-select
						:clearable="false"
						:options="zoneOptions"
						v-model="zone"
						:disabled="batch != null"
					/>
				</b-form-group>
				<b-form-group
					label-cols-sm="4"
					label-cols-lg="3"
					label="GrowArea:"
				>
					<v-select
						:clearable="false"
						:options="GAOptions"
						:reduce="ga => ga.growarea"
						label="growarea"
						v-model="growarea"
						:disabled="batch != null"
					/>
				</b-form-group>
				<b-form-group
					label-cols-sm="4"
					label-cols-lg="3"
					label="Variant:"
				>
					<v-select
						:clearable="false"
						:options="variantOptions"
						v-model="variant"
						:reduce="option => option.value"
						:disabled="batch != null"
					/>
				</b-form-group>
				<b-form-group
					label-cols-sm="4"
					label-cols-lg="3"
					label="Mass Modifier:"
					label-for="mass-mod"              
				>
					<v-select
						:options="massmodOptions"
						:clearable="false"
						v-model="massmod"
					/>
				</b-form-group>
			</b-form>
		</template>
		<template #modal-footer="{ ok }">
			<b-button size="md" variant="success" @click="ok()">
				OK
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import axios from "axios";
import store from "../../store";
import massmods_json from "../../assets/massmods.json";
export default {
    name: "HarvestConfigModal",
    props: ["farm", "batch", "updateZones"],
    data(){
		return {
			// variantOptions: variant_json,
			variantOptions: [],
			massmodOptions: massmods_json,
			GAOptions: [],
			zoneOptions: []
		}
	},
	watch: {
		farm: function(){ this.getZones(); },
		zone: function(newVal){ if(newVal != null) { this.getGrowAreas(newVal); } },
		updateZones: function(){ this.getZones(); }
	},
	computed:{
		zone: {get(){ return store.state.zone }, set(v){ store.commit('setZone', v) }},
		growarea: {get(){ return store.state.growarea }, set(v){ store.commit('setGrowarea', v) }},
		variant: {get(){ return store.state.variant }, set(v){ store.commit('setVariant', v) }},
		massmod: {get(){ return store.state.massmod }, set(v){ store.commit('setMassMod', v) }}
	},
	mounted(){
		this.getZones();
		this.getVariants();
	},
	methods: {
		getGrowAreas(zone){
			axios.get(`/${this.farm}/growareas`, { params: { zone: zone } })
			.then((response) => {
				this.GAOptions = response.data;
			})
			.catch((err) => {
				console.error(err);
				this.$emit('error', `Error retrieving growareas`);
			});
		},
		getZones(){
			axios.get(`/${this.farm}/zones`, { })
			.then((response) => {
				this.zoneOptions = response.data;
			})
			.catch((err) => {
				console.error(err);
				this.$emit('error', `Error retrieving zones`);
			});
		},
		getVariants(){
            this.variants = null;
			axios.get(`/${this.farm}/variantManagement/variants`)
			.then((res) => {
				let avariants = res.data.active;
				avariants = avariants.map((v) => {
					v.label = `${v.code || ""} ${v.colour} ${v.name || ""} ${v.group} - ${v.source}`;
					v.value = `${v.code || ""} ${v.colour} ${v.name || ""} ${v.group}`;
					return v;
				});
				this.variantOptions = avariants;
			})
			.catch((error) => {
				console.error(error);
				this.$emit('error', `Error retrieving variants`);
			})
        }
	}
}
</script>