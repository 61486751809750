import moment from "moment";

const manualHarvestStore = {
	state : {
        date: moment().format("YYYY-MM-DD")
	},
	mutations : {
		setDate(state, newDate){
			state.date = newDate;
		}
	}
}

export default manualHarvestStore;