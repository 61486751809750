<template>
    <div class="manual_harvests box-shadow-1">
      <table class="table_harvests">
        <thead class="table_harvests__header">
            <tr>
                <th class="filters" colspan="8">
                    <div>
                        <div><input class="harvests_date" type="date" v-model="inputDate" /></div>
                        <div><span v-if="harvests.data.value">{{ harvests.data.value?.length }} records</span></div>
                        <div><b-button @click="refreshHarvests()" :disabled="!disableButtons || harvests.isFetching.value"><span v-show="harvests.isFetching.value"><i class="fas fa-sync-alt fa-spin"></i></span><span v-show="!harvests.isFetching.value"><i class="fas fa-sync-alt"></i></span></b-button></div>
                    </div>
                </th>
            </tr>
            <tr>
                <th class="column_labels" v-for="col in cols" :key="col.label">
                    {{ col.label }}
                </th>
                <!-- column for buttons/status -->
                <th class="column_labels">status</th>
            </tr>
        </thead>
        <tbody>
            <tr class="table_harvests_newdata">
                <td v-for="(col, key) in newData" :key="key">
                    <span v-if="key=='date'">
                        {{ parseDate(col) }}
                    </span>
                    <!-- <div v-if="key=='zone' && growareas.isFetched.value">
                        <select form="add_harvest" v-model="newData.zone" required>
                            <option v-for="zone in zones.data.value" :key="zone">{{ zone }}</option>
                        </select>
                    </div> -->
                    <div v-else-if="key=='growarea' && growareas.isFetched.value">
                        <MultiSelect form="add_harvest" class="growareas" placeholder="" selectLabel="" deselectLabel="" v-model="newData.growarea" track-by="growarea" label="growarea" :options="growareas.data.value" :group-select="false" group-values="gas" group-label="zone" required></MultiSelect>
                    </div>
                    <div v-else-if="key=='variant' && variants.isFetched.value">
                        <MultiSelect form="add_harvest" class="variants" placeholder="" selectLabel="" deselectLabel="" track-by="value" label="label" :options="variants.data.value" v-model="newData.variant" required></MultiSelect>
                    </div>
                    <div v-else-if="key=='quantity'">
                        <input form="add_harvest" type="number" min="1" step="1" v-model="newData.quantity" required />
                    </div>
                    <div v-else-if="key=='mass'">
                        <input form="add_harvest" type="number" min="1" step="1" v-model="newData.mass" required />
                    </div>
                    <div v-else-if="key=='label'">
                        <select form="add_harvest" v-model="newData.label">
                            <option value="1">ACCEPT</option>
                            <option value="0">REJECT</option>
                        </select>
                    </div>
                    <div v-else-if="key=='mass_mod'">
                        <select form="add_harvest" v-model="newData.mass_mod">
                            <option v-for="mmod in massmodOptions" :key="mmod.name" :value="mmod.value">{{ mmod.label }}</option>
                        </select>
                    </div>
                    <!-- <div v-else-if="key=='tags'">
                        <MultiSelect form="add_harvest" class="tags" track-by="value" label="label" :options="tagsOptions" v-model="newData.tags" :multiple="true" />
                    </div> -->
                
                </td>
                <td>
                    <button form="add_harvest" type="submit" :disabled="!disableButtons">submit</button>
                </td>
            </tr>
            <tr class="table_harvests_data" v-for="row in harvests.data.value" :key="row._id">
                <td v-for="(col, key) in cols" :key="key">
                    {{ applyField(col.field, row) }}
                </td>
            </tr>
        </tbody>
      </table>
      <form id="add_harvest" @submit.prevent="useSubmitHarvest()"></form>
    </div>
</template>

<script>
import { computed, ref, toRefs } from "vue"
import { useQuery, useMutation, useQueryClient  } from '@tanstack/vue-query';
import axios from 'axios'
import moment from 'moment';
import store from "../store";
import massmods_json from "@/assets/massmods.json";
import tags_json from "@/assets/tags.json";

export default {
    props: ["farm", "batchId"],
    components:{},
    data(){
        return {
            cols: [
                {label: 'date', field: (rowObj) => `${moment(rowObj.date).format('DD/MM/YYYY HH:mm:ss')}`, type: 'date', dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSX', dateOutputFormat: 'yyyy-MM-dd HH:mm:ss'},
                // {label: 'zone', field: 'zone'},
                {label: 'growarea', field: 'growarea'},
                {label: 'variant', field:'variant'},
                {label: 'quantity', field: 'quantity'},
                {label: 'mass (g)', field: (rowObj) => `${rowObj.mass} g`, type: 'number'},
                {label: 'label', field: (rowObj) => rowObj.label == 0 ? 'REJECT' : 'ACCEPT'},
                {label: 'mod.', field: 'mass_mod'},
                // {label: 'tags', field: 'tags'}
            ],
            massmodOptions: massmods_json,
            tagsOptions: tags_json
        }
    },
    setup(props){
        const queryClient = useQueryClient()
        const { farm } = toRefs(props)

        const disableButtons = ref(true)
        const pauseSubmitButton = function(n){
            disableButtons.value = false
            setTimeout(() => disableButtons.value=true, n)
        }

        const useSubmitHarvest = function(){
            pauseSubmitButton(3000)
            addHarvest.mutate(newData)
        }

        const submitHarvest = async function(newHarvest){
            let { date, zone, growarea, variant, quantity, mass, label, mass_mod, tags } = newHarvest.value
            if(growarea == null || growarea?.growarea.length <= 0){
                throw new Error("growarea is not selected or undefined")
            }
            if(variant == null || variant?.value.length <= 0){
                throw new Error("variant is not selected or undefined")
            }
            try { 
                let response = await axios.post(`${ farm.value }/harvests/data/submit`, {
                    date, zone, growarea: growarea.growarea, variant: variant.value, quantity, mass, label, mass_mod, tags
                })

                return response
            } catch(err) {
                throw new Error(err)
            }
        }

        const getHarvests = async function(inputDate){
            let mom_date = moment(inputDate, "YYYY-MM-DD")            
            if(!mom_date.isValid()){
                console.log(mom_date)
                throw new Error("date is not valid")
            }
            try {
                let response = await axios.get(`${ farm.value }/harvests/data`, {
                    params: { 
                        startDate: mom_date.startOf('day').toISOString(),
                        endDate: mom_date.endOf('day').toISOString()
                    }
                })
                return response.data
            } catch(err){
                throw new Error(err)
            }
        }

        // const getZones = async function(){
        //     try {
        //         let response = await axios.get(`${ farm.value }/zones`, {})
        //         let zones = response.data
        //         return zones
        //     }
        //     catch(err){
        //         throw new Error(err)
        //     }
        // }

        const getGrowAreas = async function(){
            try {
                let response = await axios.get(`${ farm.value }/growareas`, {})
                let growareas = []
                for (var ga in response.data){
                    growareas.push({
                        zone: ga,
                        gas: JSON.parse(JSON.stringify(response.data[ga]))
                    });
                }
                return growareas
            }
            catch(err){
                throw new Error(err)
            }
        }

        const getVariants = async function(){
            try {
                let response = await axios.get(`/${ farm.value }/variantManagement/variants`)
                let avariants = response.data.active;
                avariants = avariants.map((v) => {
                    v.label = `${v.code || ""} ${v.colour} ${v.name || ""} ${v.group} - ${v.source}`;
                    v.value = `${v.code || ""} ${v.colour} ${v.name || ""} ${v.group}`;
                    return v;
                });
                return avariants
            } catch(err) {
                throw new Error(err)
            }
        }

        const inputDate = computed({
            get(){ return store.state.date },
            set(v){ store.commit('setDate', v) }
        })

        const parseDate = function(v){ return moment(v).format("DD/MM/YYYY")}

        const applyField = function(val, rowObj) {
            if (typeof val == 'string') {
                return rowObj[val]
            } else {
                return val(rowObj)
            }
        }

        const refreshHarvests = function(){
            pauseSubmitButton(1000)
            queryClient.invalidateQueries({queryKey: ['harvests', farm, { inputDate }]})
        }

        const newData = ref({date: inputDate.value, growarea: null, variant: null, quantity: 1, mass: 1, label: 1, mass_mod: 0})
        // why it doesnt immediately, refresh data?
        const addHarvest = useMutation({
            mutationFn:  (newHarvest) => submitHarvest(newHarvest), 
            onSuccess: () => { refreshHarvests() }
        })
        const harvests = useQuery({queryKey: ['harvests', farm, { inputDate }], queryFn: () => getHarvests(inputDate.value), staleTime: 30 * 1000})
        const variants = useQuery({queryKey: ['variants', farm], queryFn: () => getVariants(), staleTime: 2* 60 * 1000})
        const growareas = useQuery({queryKey: ['growareas', farm, {}], queryFn: () => getGrowAreas(), staleTime: 2 * 60 * 1000})
        // const zones = useQuery({queryKey: ['zones', farm], queryFn: () => getZones(), staleTime: 2 * 60 * 1000})

        return { harvests, variants, growareas, parseDate, applyField, inputDate, newData, addHarvest, useSubmitHarvest, disableButtons, refreshHarvests }
    },
}
</script>

<style>
input {
    width: 75px;
}

select {
    min-width: 90px;
}

.harvests_date {
    width: 110px;
}

.multiselect.tags {
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 200px;
    min-width: 200px;
    font-size: 14px; 
}

.multiselect.growareas {
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 200px;
    min-width: 100px;
    font-size: 14px;
}

.multiselect.variants {
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 200px;
    min-width: 250px;
    font-size: 14px;
}

.manual_harvests {
    border-radius: 10px;
    padding: 2px;
    display: flex;
    justify-content: center;
}

.filters {
    position: sticky;
    top: 81px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.filters > div {
    display: grid;
    grid-auto-flow: column;
}

.table_harvests_newdata td {
    position: sticky;
    top: 160px;
    background-color: rgba(128,128,32,0.33);
    backdrop-filter: blur(5px);
    border-bottom: 2px solid rgba(32,32,32,0.66);
    border-top: 1px solid rgba(32,32,32,0.66);
    border-left: 1px solid rgba(32,32,32,0.66);
    border-right: 1px solid rgba(32,32,32,0.66);
    z-index: 10;
}

.column_labels {
    position: sticky;
    top: 130px;
}

.table_harvests__header th {
    background-color: rgba(32,32,32,0.33);
    backdrop-filter: blur(5px);
    border-bottom: 2px solid rgba(32,32,32,0.66);
    border-top: 1px solid rgba(32,32,32,0.66);
    border-left: 1px solid rgba(32,32,32,0.66);
    border-right: 1px solid rgba(32,32,32,0.66);
    font-weight: 800;
    z-index: 10;
}

.table_harvests {
  table-layout: fixed;
  border-collapse: separate;
  border: 2px solid rgba(32,32,32,0.66);
  border-radius: 10px;
  border-spacing: 0px;
}

.table_harvests td {
    border-right: 1px solid #f2f2f2;
    border-left: 1px solid #f2f2f2;
}

.table_harvests th,
.table_harvests td {
  padding: 0.25em;
}

.table_harvests td {
  border-bottom: 1px solid #f2f2f2;
}
</style>
