import Vue from 'vue';
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

function userAdminVerify(_to, _from, next){
  const user =  store.state.user;
  if (user.role == 'admin') { next(); }
  else { next({ name: 'error' }); }
}

const routes = [
  // route to home page
  {
    name: 'home',
    path: "/",
    component: () => import("../views/Welcome.vue")
  },
  // route to crops page
  {
    name: 'crops',
    path: "/crops/:farm",
    component: () => import("../views/Crops.vue"),
    props: route => ({ farm: route.params.farm, zone: route.query.zone, growarea: route.query.growarea })
  },
  //route to harvest page
  {
    name: 'harvest',
    path: "/harvest/:farm",
    component: () => import("../views/HarvestWiz.vue"),
    props: route => ({ farm: route.params.farm, batchId: route.query.batid })
  },
  //route to manual harvest entry
  {
    name: 'manualharvest',
    path: "/manualharvest/:farm",
    component: () => import("../views/ManualHarvest.vue"),
    props: route => ({ farm: route.params.farm }),
    beforeEnter: userAdminVerify
  },
  // Route to seed inventory page
  {
    name: 'seedinventory',
    path: "/seedinventory/:farm",
    component: () => import("../views/SeedInventory.vue"),
    props: route => ({ farm: route.params.farm })
  },
  // Route to growarea sensor dashboard
  {
    name: 'growareas',
    path: "/growareas/:farm",
    component: () => import("../views/GrowArea.vue"),
    props: route => ({ farm: route.params.farm })
  },
  // Route to yield dashboard
  {
    name: 'yield',
    path: "/yield/:farm",
    component: () => import("../views/Yield.vue"),
    props: route => ({ farm: route.params.farm, sdate: route.query.sdate, edate: route.query.edate })
  },
  // Route to task dashboard page
  {
    name: "tasks",
    path: "/tasks/:farm",
    component: () => import("../views/Tasks.vue"),
    props: route => ({ farm: route.params.farm })

  },
  // Route to planting plan
  {
    name: 'plantingplan',
    path: "/plantingplan/:farm",
    component: () => import("../views/PlantingPlan.vue"),
    props: route => ({ farm: route.params.farm })
  },
  //route to logistics page
  {
    name: 'logistics',
    path: "/logistics/:farm",
    component: () => import("../views/SalesAndLogistics.vue"),
    props: route => ({ farm: route.params.farm })
  },
  // Route to login page
  {
    name: 'login',
    path: "/login",
    component: () => import("../views/Login.vue")
  },
  //route to password set
  {
    name: 'passwordset',
    path: "/passwordset",
    component: () => import("../views/PasswordSet.vue")
  },
  //route to forgot pass
  {
    name: 'forgotpass',
    path: "/forgotpass",
    component: () => import("../views/ForgotPass.vue")
  },
  //route to Error page
  {
    name: 'error',
    path: "/error",
    component: () => import("../views/Error.vue")
  },
  //route to user management page
  {
    name: 'users',
    path: "/users/:farm",
    component: () => import("../views/UserManagement.vue"),
    props: route => ({ farm: route.params.farm }),
    beforeEnter: userAdminVerify
  },
  //route to variant management page
  {
    name: 'variants',
    path: "/variants/:farm",
    component: () => import("../views/VariantManagement.vue"),
    props: route => ({ farm: route.params.farm }),
    beforeEnter: userAdminVerify
  },
];

const router = new VueRouter({
  routes
});

export default router;
